<template>
  <div class="solutionFooter">
    <div class="mainContent">
      <div class="leftBox">
        <img :src="require(`@/assets/images/commonUse/service.png`)" alt="" />
      </div>
      <div class="rightBox">
        <div class="rightTitle">定制化服务</div>
        <div class="rightText">
          我们能为您提供个性化的定制服务，定制周期短，您只需将需求告知我们，我们会为您提供一套合适的解决方案。
        </div>

        <div class="rightText">service@ecoprobio.cn</div>
        <div class="rightText">400-806-1696</div>
      </div>
    </div>
    <div class="mainContent-m">
      <div class="leftBox">
        <img :src="require(`@/assets/images/commonUse/service.png`)" alt="" />
      </div>
      <div class="rightBox">
        <div class="rightTitle">定制化服务</div>
        <div class="rightText">
          我们能为您提供个性化的定制服务，定制周期短，您只需将需求告知我们，我们会为您提供一套合适的解决方案。
        </div>

        <div class="rightText">service@ecoprobio.cn</div>
        <div class="rightText">400-806-1696</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'solutionFooter',

  props: {}
}
</script>
<style lang="scss" scoped>
.solutionFooter {
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  height: 480px;
  background: #0e293b;
  padding: 80px 0 80px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .mainContent {
    position: relative;
    height: 100%;
    width: 1200px;
    // display: flex;
    // justify-content: space-between;
    .leftBox {
      position: absolute;
      top: 0px;
      right: 670px;
      // margin-right: 80px;
      width: 808px;
      height: 320px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .rightBox {
      position: absolute;
      top: 80px;
      left: 608px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 592px;
      .rightTitle {
        margin-bottom: 20px;
        font-family: HarmonyOS Sans SC;
        font-size: 24px;
        font-weight: 900;
        line-height: 24px;

        letter-spacing: 0em;

        color: #ffffff;
      }
      .rightText {
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-weight: 300;
        line-height: 32px;
        letter-spacing: 0em;

        color: #ffffff;
      }
    }
  }

  .mainContent-m {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    .leftBox {
      // margin-right: 80px;
      width: 335px;
      height: 160px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .rightBox {
      display: flex;
      flex-direction: column;
      // justify-content: center;
      // width: 592px;
      margin-top: 20px;
      .rightTitle {
        margin-bottom: 16px;
        font-family: HarmonyOS Sans SC;
        font-size: 20px;
        font-weight: 300;
        line-height: 20px;

        letter-spacing: 0em;

        color: #ffffff;
      }
      .rightText {
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 0em;

        color: #ffffff;
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .solutionFooter {
    height: 420px;
    padding: 40px 20px 40px 20px;

    .mainContent {
      display: none;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .solutionFooter {
    .mainContent-m {
      display: none;
    }
  }
}
</style>
