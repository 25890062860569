<template>
  <div class="solution">
    <navHeader :index="1" :idx="parseInt(this.$route.query.type) + 1"></navHeader>
    <div class="picWrapper" :style="picWrapperArr[this.$route.query.type].style">
      <div class="topProfile">
        <div class="topTitle">
          <!-- {{ picWrapperArr[this.$route.query.type].title }} -->
          <i
            class="iconfont"
            :class="picWrapperArr[this.$route.query.type].iconfont"
            :style="this.$route.query.type == 2 ? 'color:#2C4150' : ''"
          ></i>
        </div>
        <div class="topText" :style="this.$route.query.type == 2 ? 'color:#2C4150' : ''">
          {{ picWrapperArr[this.$route.query.type].text }}
        </div>

        <div class="topImg">
          <img class="" :src="picWrapperArr[this.$route.query.type].pic" alt="" />
        </div>
      </div>
      <div class="topProfile-m">
        <div class="topImg">
          <img
            class=""
            :src="picWrapperArr[this.$route.query.type].picx2"
            :style="picWrapperArr[this.$route.query.type].picStyle"
            alt=""
          />
        </div>
        <div class="topTitle">
          <!-- {{ picWrapperArr[this.$route.query.type].title }} -->

          <i
            class="iconfont"
            :class="picWrapperArr[this.$route.query.type].iconfont"
            :style="this.$route.query.type == 2 ? 'color:#2C4150' : ''"
          ></i>
        </div>
        <div class="topText" :style="this.$route.query.type == 2 ? 'color:#2C4150' : ''">
          {{ picWrapperArr[this.$route.query.type].text }}
        </div>
      </div>
    </div>
    <!-- 行业背景 -->

    <!-- <div class="interContainer">
      <div class="pin-spacer">
        <div class="interiorAnimationWrapper">
          <div class="Interiors_interior__heFlo">
            <div class="Interiors_interiorImageWrapper__kwDBV">
              <div class="responsive-image--picture">
                <img class="farm1" :src="backgroundDetail[this.$route.query.type].pic" alt="" />
              </div>
            </div>
            <div class="Interiors_interiorTextWrapper__OXo3A">
              <div class="farm1DetailBoxTitle">{{ backgroundDetail[this.$route.query.type].title }}</div>
              <div class="farm1DetailBoxText">
                {{ backgroundDetail[this.$route.query.type].text }}
              </div>
            </div>
          </div>
          <div class="Interiors_interior__heFlo">
            <div class="Interiors_interiorImageWrapper__kwDBV">
              <div class="responsive-image--picture">
                <img class="farm1" :src="backgroundDetail[this.$route.query.type].pic1" alt="" />
              </div>
            </div>
            <div class="Interiors_interiorTextWrapper__OXo3A">
              <div class="farm1DetailBoxTitle">{{ backgroundDetail[this.$route.query.type].title1 }}</div>
              <div class="farm1DetailBoxText">
                {{ backgroundDetail[this.$route.query.type].text1 }}
              </div>
            </div>
          </div>
    
        </div>
      </div>
    </div> -->

    <!-- <div class="backgroundDetail">
      <img class="farm1" :src="backgroundDetail[this.$route.query.type].pic" alt="" />
      <div class="farm1DetailBoxTitle">{{ backgroundDetail[this.$route.query.type].title }}</div>
      <div class="farm1DetailBoxText">
        {{ backgroundDetail[this.$route.query.type].text }}
      </div>
    </div> -->

    <div class="content" id="content">
      <!-- 模拟图片元素的盒子 -->
      <div class="cover-wrapper" :style="{ paddingBottom: getpbottom + 'px' }">
        <!-- 固定定位的盒子主要是让cover元素固定在页面最上层,判断滚动到位置了 -->
        <div
          class="cover-container"
          :style="{
            transform: 'translate3d(' + '0px' + ',' + (isShowAnimation ? 0 : getpbottom - vh) + 'px' + ',' + '0px)'
          }"
          :class="isFixed && isShowAnimation ? 'flex' : ''"
        >
          <!-- 放置图片的盒子，监控滚轮事件修改高度动态显示图片 -->
          <div class="cover-box" :style="{ zIndex: '4', height: '100%' }">
            <div class="detail">
              <picture>
                <!-- <picture :style="{ height: changenum + '%' }"> -->
                <!-- 展示图片1 -->
                <img class="cover-pic" :src="backgroundDetail[this.$route.query.type].pic" alt="" />
              </picture>
              <div
                class="cover-text"
                :style="{
                  transform: 'translate3d(' + '0px' + ',' + textBoxTranslate1 + 'px' + ',' + '0px)'
                }"
              >
                <div class="cover-textTitle">{{ backgroundDetail[this.$route.query.type].title }}</div>
                <div class="cover-textContent">{{ backgroundDetail[this.$route.query.type].text }}</div>
              </div>
            </div>
          </div>
          <div class="cover-box" :style="{ zIndex: '3', height: '100%' }">
            <div class="detail">
              <picture>
                <!-- 展示图片2 -->
                <img class="cover-pic" :src="backgroundDetail[this.$route.query.type].pic1" alt="" />
              </picture>
              <div
                class="cover-text textTranslate"
                :style="{
                  transform: 'translate3d(' + '0px' + ',' + textBoxTranslate2 + 'px' + ',' + '0px)'
                }"
              >
                <div class="cover-textTitle">{{ backgroundDetail[this.$route.query.type].title1 }}</div>
                <div class="cover-textContent">{{ backgroundDetail[this.$route.query.type].text1 }}</div>
              </div>
            </div>
          </div>
          <!-- <div class="cover-box" :style="{ zIndex: '2', height: '100%' }">
            <div class="detail">
              <picture>
              
                <img
                  class="cover-pic"
                  src="https://img0.baidu.com/it/u=3526761496,3758949724&fm=253&fmt=auto&app=138&f=JPEG?w=667&h=500"
                  alt=""
                />
              </picture>
              <div
                class="cover-text"
                :style="{
                  transform: 'translate3d(' + '0px' + ',' + textBoxTranslate3 + 'px' + ',' + '0px)'
                }"
              >
                图片3
              </div>
            </div>
          </div> -->
          <!-- <div class="cover-box" :style="{ zIndex: '1', height: '100%' }">
            <div class="detail">
              <picture>
                
                <img
                  class="cover-pic"
                  src="https://img0.baidu.com/it/u=3016247431,198114131&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=313"
                  alt=""
                />
              </picture>
              <div class="cover-text">图片4</div>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <div class="content-m" id="content-m">
      <div class="cover-box-m">
        <div class="detail-m">
          <img class="cover-pic-m" :src="backgroundDetail[this.$route.query.type].pic" alt="" />

          <div class="cover-text-m">
            <div class="cover-textTitle">{{ backgroundDetail[this.$route.query.type].title }}</div>
            <div class="cover-textContent">{{ backgroundDetail[this.$route.query.type].text }}</div>
          </div>
        </div>
      </div>
      <div class="cover-box-m">
        <div class="detail-m">
          <img class="cover-pic-m" :src="backgroundDetail[this.$route.query.type].pic1" alt="" />

          <div class="cover-text-m">
            <div class="cover-textTitle">{{ backgroundDetail[this.$route.query.type].title1 }}</div>
            <div class="cover-textContent">{{ backgroundDetail[this.$route.query.type].text1 }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="advantage">
      <div
        :class="[cindex % 2 === 0 ? 'advantageItem1' : 'advantageItem2']"
        v-for="(citem, cindex) in advantageArr[this.$route.query.type]"
        :key="cindex"
      >
        <img class="advantageImage" :src="citem.pic" alt="" />
        <div class="advantageBox" :class="citem.title === '便捷的农业智能化和可视化' ? 'advantageBoxSpecial' : ''">
          <div class="advantageTitle">
            <p class="">{{ citem.title }}</p>
            <p class="">{{ citem.title1 ? citem.title1 : '' }}</p>
          </div>
          <div class="advantageBoxText">{{ citem.text }}</div>
        </div>
      </div>
    </div>
    <div class="compareCase" v-if="this.$route.query.type == 5">
      <div class="compareContent">
        <div class="compareBigTitle">对照试验</div>
        <div class="compareContentBox">
          <div class="compareContentItem" v-for="(pareItem, pareIndex) in compareArr" :key="pareIndex">
            <img class="" :src="pareItem.pic" alt="" />
            <div class="compareDetailBoxDes">
              <div
                class="compareDetailBoxDesLine"
                v-for="(contentItem, contentIndex) in pareItem.content"
                :key="contentIndex"
              >
                <div class="title">{{ contentItem.title }}</div>
                <div class="text">{{ contentItem.text }}</div>
              </div>
            </div>
            <div class="compareDetailBoxDes">
              <div class="title">{{ pareItem.resultTitle }}</div>
              <div class="text">{{ pareItem.resultText }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <solutionFooter></solutionFooter>
    <rightsFooter></rightsFooter>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import navHeader from '@/components/navHeader.vue'
import rightsFooter from '@/components/rightsFooter.vue'
import solutionFooter from '@/components/solutionFooter.vue'

export default {
  name: 'Solution',
  components: {
    navHeader,
    rightsFooter,
    solutionFooter
  },
  data() {
    return {
      picWrapperArr: [
        {
          title: '数字薄膜大棚',
          text: '我们的方案是一套非常简单易用和高效的数字薄膜大棚管理方案，它全程监测作物生长过程，并能灵活控制棚内设施，降低人工并促进增产提质，为用户带来更多实际价值，实现传统农业的智能升级!',
          style: 'background: #313967',
          pic: require(`@/assets/images/solution/profile1.png`),
          picx2: require(`@/assets/images/solution/profile1x2.png`),
          picStyle: 'width:335px;height:190px',
          iconfont: 'icon-shuzibaomodapeng'
        },
        {
          title: '数字果园大田',
          text: '我们的数字果园大田方案不仅仅是一套设备，更是一整套基于手机和电脑端的科学生产管理系统，能够 帮助农民降低成本，提高效率，实现增产提质。',
          style: 'background: #338A72',
          pic: require(`@/assets/images/solution/profile2.png`),
          picx2: require(`@/assets/images/solution/profile2.png`),
          picStyle: 'width:337.5px;height:180px',
          iconfont: 'icon-shuziguoyuandatian'
        },
        {
          title: '数字玻璃温室',
          text: '我们的数字玻璃温室方案结合智能监测和控制中心软硬件，联动监测和智能设备， 增强温室运行效率和准确性，有效提升农场运营和管理水平，强化农场核心竞争 力，且无需替换现有设施设备',
          style: 'background: #94CAD4 ',
          pic: require(`@/assets/images/solution/profile3.png`),
          picx2: require(`@/assets/images/solution/profile3.png`),
          picStyle: 'width:335px;height:184.5px',
          iconfont: 'icon-shuziboliwenshi'
        },
        {
          title: '数字水产',
          text: '尚阳数字水产养殖系统，集成不同形态的水质监测设备、智控设备以及视频监控， 通过物联网平台采集水质核心参数:水温、电导率、PH、溶解氧、余氯、ORP 等， 结合水产养殖模型，及时在手机端进行预警以及自动启停增氧泵、进水泵、投料、 补光灯等设施，保证水质稳定以提供鱼虾蟹适宜的生长环境。',
          style: 'background: #33395B ',
          pic: require(`@/assets/images/solution/profile4.png`),
          picx2: require(`@/assets/images/solution/profile4.png`),
          picStyle: 'width:335px;height:213.2px',
          iconfont: 'icon-shuzishuichan'
        },
        {
          title: '数字无土栽培',
          text: '尚阳数字栽培系统，实时监测无土栽培中的空气和水质环境以及，精准掌握作物作物生长环境;同时根据微生态和作物生长模型，智能控制补光灯和循环水泵，让植物得到最恰当的光照、氧气和营养，更好的促进作物生长。我们还提供移动端小程序和web端管理大屏，方便您随时随地查看监测数据和控制设备，真正实现无人看管，无时差服务。',
          style: 'background: #A97399',
          pic: require(`@/assets/images/solution/profile5.png`),
          picx2: require(`@/assets/images/solution/profile5.png`),
          picStyle: 'width:208px;height:200px',
          iconfont: 'icon-shuziwutuzaipei'
        },
        {
          title: '草莓数字种植应用',
          text: '尚阳草莓数字种植方案能够便捷监测种植环境，智能联动卷帘和灌溉以维持适宜生长环境;在此基础上提供一套科学的草莓营养方案，针对草莓各生长周期的营养需求，提供有针对性的营养组合套装，促进草莓生长、抵御病虫害、增快转色、增大果重、提高糖度、延长货架期;同时通过数字管理系统，记录草莓生长周期各项参数，形成作物追溯报告和生产经验总结报告，帮助生产企业提高科学管理水平。',
          style: 'background: #B24244',
          pic: require(`@/assets/images/solution/profile6.png`),
          picx2: require(`@/assets/images/solution/profile6x2.png`),

          picStyle: 'width:335px;height:210px',
          iconfont: 'icon-caomeishuzizhongzhiyingyong'
        }
      ],
      backgroundDetail: [
        {
          title: '行业背景',
          text: '数字薄膜大棚作为一种新兴的农业形态，随着城市规划对绿地面积的限制和生产能力的提高，其逐渐被越来越多的人所接受和运用。其通过设备自动控制、信息互联等手段，实现了农业生产的数字化和智能化，同时也提升了农业生产的效率和质量。',
          pic: require(`@/assets/images/solution/background1.png`),
          title1: '行业现状',
          text1:
            '目前国内数字薄膜大棚的市场规模已超过百亿元，未来还有较大的发展空间。然而，在发展数字薄膜大棚时也需要注意合理利用资源、科学施肥、避免污染等问题，以实现可持续发展。',
          pic1: require(`@/assets/images/solution/background7.png`)
        },
        {
          title: '行业背景',
          text: '数字果园以大数据、云计算、物联网等新一代信息技术为支撑，深度融合了种植、养殖、加工等诸多环节的数据管理与应用，优化农业生产过程，提高资源利用效率，实现精准农业、高效农业、可持续农业。',
          pic: require(`@/assets/images/solution/background2.png`),
          title1: '行业现状',
          text1:
            '现阶段，全省数字化果园的研究与应用逐步深入，政府和规模化经营主体已经认识到数字化技术应用对于提高果园精细管理、提升果品质量和营销管理水平的巨大效益，开始投入人力、物力和财力到数字化果园建设中，但与欧美等发达国家相比仍属新兴领域，依然面临着诸多问题',
          pic1: require(`@/assets/images/solution/background8.png`)
        },
        {
          title: '行业背景',
          text: '数字玻璃温室采用先进的智能化控制系统，通过自动化、数字化的手段对温室环境进行监测、调控和管理，从而提高温室内作物的品质和产量。随着我国经济的快速发展和人民生活水平的提高，人们对健康、安全、优质的食品需求越来越强烈，加之气候变化和土地资源的限制，使得数字玻璃温室作为一种新型的农业生产方式逐渐崭露头角，受到了社会各界的广泛关注和认可。',
          pic: require(`@/assets/images/solution/background3.png`),
          title1: '行业现状',
          text1:
            '目前，数字玻璃温室建筑面积呈扩大化趋势，立体栽培、机械化作业、潮汐灌溉、滴灌系统得到广泛应用。随着科学技术的发展，数字玻璃温室的控制技术也在发生变化，在实现了控制自动化的同时，进行了人工智能的广泛应用研究，开发了用于温室管理、决策、咨询等方面的专家系统，实现了温室的远程监控、管理诊断、实时环境监控、完全自动化，同时为工作人员提供信息技术支持与服务、气象信息等各类信息服务。',
          pic1: require(`@/assets/images/solution/background9.png`)
        },
        {
          title: '行业背景',
          text: '我国数字水产研究起步于20世纪90年代，2011年在江苏建设了我国首个物联网水产养殖示范基地。2012年全国水产技术推广总站开发应用水生动物疾病远程辅助诊断服务网。在具体实践过程中，渔业发展需求不断变化，数字水产也根据功能不断细分出各子系统。',
          pic: require(`@/assets/images/solution/background4.png`),
          title1: '行业现状',
          text1:
            '数字水产培育是一种通过人工智能、物联网和大数据等技术手段，对水产养殖过程进行数字化管理和监控的创新方式。目前水产养殖业面临着环境污染、饲料成本高、病害易发等问题，而数字化管理可以提高水产养殖的效率和质量，减少损失并降低环境压力。数字水产培育在水产养殖业内逐渐得到了广泛应用，并且有着广阔的市场前景和潜力。',
          pic1: require(`@/assets/images/solution/background10.png`)
        },
        {
          title: '行业背景',
          text: '以习近平新时代中国特色社会主义思想为指导，以产业数字化为发展主线，以数据为关键生产要素，着力建设基础数据资源体系，加强数字生产能力建设，加快管理服务数字化改造，强化关键技术装备创新和重大工程设施建设，推动政府信息系统和公共数据互联开放共享，用数字化引领驱动农业现代化。',
          pic: require(`@/assets/images/solution/background5.png`),
          title1: '行业现状',
          text1:
            '目前，数字无土栽培已经广泛应用于蔬菜、水果等植物的生产中，并且在农业科技创新、食品安全保障、城市农业发展等方面具有广阔的应用前景。同时，数字无土栽培也是一个涉及机器学习、云计算、物联网、大数据等多个前沿技术领域的复合型产业，对于推动数字经济的发展和提高农业现代化水平具有重要意义。',
          pic1: require(`@/assets/images/solution/background11.png`)
        },
        {
          title: '行业背景',
          text: '尚阳草莓数字种植方案能够便捷监测种植环境，智能联动卷帘和灌溉以维持适宜生长环境;在此基础上提供一套科学的草莓营养方案，针对草莓各生长周期的营养需求，提供有针对性的营养组合套装，促进草莓生长、抵御病虫害、增快转色、增大果重、提高糖度、延长货架期;同时通过数字管理系统，记录草莓生长周期各项参数，形成作物追溯报告和生产经验总结报告，帮助生产企业提高科学管理水平。',
          pic: require(`@/assets/images/solution/background6.png`),
          title1: '行业现状',
          text1:
            '目前，数字草莓种植行业已经在全球范围内得到了广泛的推广和应用。通过种植数据的分析和管理，数字化技术可以帮助农民精确掌握草莓种植的各种参数，包括温度、湿度、光照等，从而实现对草莓生长环境的精细化管理。同时，数字化技术还可以帮助农民优化草莓的种植方案，提高草莓品质和产量，提高种植效益。',
          pic1: require(`@/assets/images/solution/background12.png`)
        }
      ],
      advantageArr: [
        [
          {
            title: '灵活便捷 简单易用',
            text: '采用数字化技术和薄膜材料进行设计改造，提供更加灵活便捷的种植环境，并且支持太阳能供电，4G或LORA通讯协议，无需拉电拉网，减少对传统能源的依赖，更加环保。悬挂设备，扫码即用，操作简单。',
            pic: require(`@/assets/images/solution/advantageA1.png`)
          },
          {
            title: '多环境参数选择',
            text: '通过传感器实时监测大棚内的空气温度、空气湿度、光照、二氧化碳、土壤温度、土壤湿度、土壤电导度、PH等环境参数，并根据不同的作物需求调整棚内环境参数，保证良好的生长环境。提高作物的产量和质量，降低生产成本。',
            pic: require(`@/assets/images/solution/advantageA2.png`)
          },
          {
            title: '实时追踪棚内环境',
            text: '部署各种传感器对数据采集，动态追踪微生态环境变化，记录变化曲线，环境异常时及时发出报警，低温预警，高湿提醒，病虫害易发提醒，更好的了解环境变化规律并进行预测，从而优化种植计划和管理策略',
            pic: require(`@/assets/images/solution/advantageA3.png`)
          },
          {
            title: '节省人工和时间成本',
            text: '采用数字技术控制大棚卷帘、灌溉电磁阀和其他设施，远程监测和调节作物生长环境，实现自动化管控。通过手机或电脑随时随地掌握棚内环境，在异地或夜晚也可足不出户管理大棚。',
            pic: require(`@/assets/images/solution/advantageA4.png`)
          }
        ],
        [
          {
            title: '实时跟踪现场环境',
            text: '数字果园大田通过安装传感器，对果园和大田的多项环境参数（空气温湿度、光照、二氧化碳、大气压、风速、风向、PM2.5、PM10、降雨量、土壤EC、土壤PH等）进行实时监测，获取数据后进行分析，提供精准、实时的环境数据。',
            pic: require(`@/assets/images/solution/advantageB1.png`)
          },
          {
            title: '科学灌溉和施肥',
            text: '数字果园大田可以根据实时监测到的数据，智能水肥机自动调节灌溉和施肥量，避免过度或不足灌溉造成的水资源浪费和农作物死亡，且能够远程和定时控制。同时，数字果园大田还提供合理的施肥建议，以保证农作物得到足够的营养，增加产量。',
            pic: require(`@/assets/images/solution/advantageB2.png`)
          },
          {
            title: '病虫害预警、预警及防治方案',
            text: '数字果园大田可以通过智能识别技术，根据环境状况，及时发现并预警作物病虫害，帮助用户采取措施，避免病虫害扩散。同时提供防治病虫害的方案和建议，减少损失。',
            pic: require(`@/assets/images/solution/advantageB3.png`)
          },
          {
            title: '适应多种地理条件',
            text: '支持太阳能供电，4G或LORA通讯协议，无需拉电拉网，减少对传统能源的依赖，更加环保。根据不同地理条件、气候等环境因素进行调整，以满足不同作物对环境的需求。',
            pic: require(`@/assets/images/solution/advantageB4.png`)
          }
        ],
        [
          {
            title: '便捷的农业智能化和可视化',
            text: '温室内具有卷帘机、遮阳机、风机、湿帘、水肥机、补光灯、摄像头等各种设施，通过数字化监测系统，温室内各项数据都可以实时传输到控制中心，实现对温度、湿度、光照、二氧化碳含量等环境因素进行实时监测和自动调节，保障作物生长的稳定性和优质性，在管理大屏上可以随时随地查看温室内的环境数据、植物生长数据、设备状态等信息，了解作物生长状态和健康状况，及时采取正确的管理措施。',
            pic: require(`@/assets/images/solution/advantageC1.png`)
          },
          {
            title: '精准化管理 增强管理水平',
            text: '利用先进的传感器技术和智能化控制系统，数字玻璃温室可以对光照、温度、湿度、二氧化碳浓度等参数进行实时监测，并进行智能化控制，使温室内的环境得到最佳的调节和控制，从而提高作物产量和品质。',
            pic: require(`@/assets/images/solution/advantageC2.png`)
          },
          {
            title: '提高管理效率 降低管理成本',
            text: '传统的温室需要大量的人力物力投入，但数字玻璃温室的智能化管理和可视化监控，可以代替人工巡检、调节环境等操作，用户可以根据作物品种、生长周期等特征，进行精准施肥、灌溉、通风等操作，最大限度地满足作物需求，增强管理水平。',
            pic: require(`@/assets/images/solution/advantageC3.png`)
          },
          {
            title: '数据资产沉淀',
            text: '实现知识管理和智能决策。利用先进的算法技术和数据分析方法，可以将温室内各项环境参数、植物生长数据、农事、设备运行日志等信息进行有效的存储和分析，帮助农场更好地管理优化生产过程，提高温室生产效益，从而提升核心价值。',
            pic: require(`@/assets/images/solution/advantageC4.png`)
          }
        ],
        [
          {
            title: '水质监测设备形态丰富',
            title1: '安装便捷 使用简单',
            text: '数字水产具备智能化功能，设有水质监测浮漂和水质监测站，自动调节养殖环境，适应不同需求；采用太阳能供电，部署简易；微信小程序扫码即用。',
            pic: require(`@/assets/images/solution/advantageD1.png`)
          },
          {
            title: '实时监测 优化养殖环境',
            text: '数字水产通过传感器实时监测水质、水温、氧气含量、温度PH值等关键数据，通过手机报警，预防养殖塘过冷过热，确保养殖过程中的稳定性和安全性。根据养殖需求，可以精确控制水质、水温、氧气含量等，有效地优化了养殖环境。',
            pic: require(`@/assets/images/solution/advantageD2.png`)
          },
          {
            title: '联动环控设备',
            title1: '提供生产效率和产品质量',
            text: '自动根据环境联动其他环境控制设备，例如增氧泵、进水泵，因此可以大幅提高生产效率和产品质量。同时，智慧水产还支持数据分析，可以帮助用户更好地管理养殖场，提高养殖收益。',
            pic: require(`@/assets/images/solution/advantageD3.png`)
          }
        ],
        [
          {
            title: '兼容性广 不占空间',
            text: '可部署于多种不同无土种植架，例如立式、塔式等，核心控制盒小巧轻便，尺寸约为：90*45*160mm，且不需要大量的人工维护，高效省时。',
            pic: require(`@/assets/images/solution/advantageE1.png`)
          },
          {
            title: '实时监测空气和水质环境',
            text: '具有高精度的传感器，能够实时监测空气中的温度、湿度、CO2浓度等参数以及水pH值、水EC值、水溶解氧含量等参数，更好地控制种植环境，保证植物的生长和发育。',
            pic: require(`@/assets/images/solution/advantageE2.png`)
          },
          {
            title: '智能控制补光灯和循环水泵',
            text: '基于多种不同策略，我们的产品可以智能地控制补光灯和循环水泵，根据不同的作物类型和生长阶段，提供最优化的生长环境，使植物能够快速生长并获得更高的产量和品质。',
            pic: require(`@/assets/images/solution/advantageE3.png`)
          },
          {
            title: '高效监测数据和控制设备',
            text: '提供小程序，便捷操控，同时提供不同尺寸触摸管理大屏可集成于不同的无土栽培设施，随时了解植物生长的情况和环境参数的变化等。',
            pic: require(`@/assets/images/solution/advantageE4.png`)
          }
        ],
        [
          {
            title: '个性化管理',
            text: '数字化技术和农业技术相结合，以数据指导生产。根据用户所种植草莓的生长周期、地理位置等因素进行定制化管理，并提供种植方案和管理建议。',
            pic: require(`@/assets/images/solution/advantageF1.png`)
          },
          {
            title: '实时监测 高效管理',
            text: '实时监测草莓生长环境，包括空气的温湿度、土壤EC和PH值等重要指标，并根据历史病虫害发生情况进行预警；同时提供种植建议，根据土壤的EC和PH值判断何时施肥，施什么肥，帮助用户科学合理地管理；还可以根据天气情况判断是否需要提前或延迟开关大棚，从而更好地保护作物。',
            pic: require(`@/assets/images/solution/advantageF2.png`)
          },
          {
            title: '提供科学营养方案',
            text: '针对各生长周期不同的营养需求，提供有针对性的营养套装，通过富含大量元素、微量元素、腐植酸、氨基酸等优质投入品套装，促进草莓生长、抵御病虫害、增快转色、增大果重、提高糖度、延长货架期。',
            pic: require(`@/assets/images/solution/advantageF3.png`)
          },
          {
            title: '数字管理系统',
            title1: '协助形成数字资产',
            text: '通过数字管理系统，记录草莓生长周期各项参数，包括但不限于生长环境曲线、农事记录、施肥施药记录、设施运行日志、各生长周期现场照片，形成作物追溯报告和生产经验总结报告，帮助生产企业提高科学管理水平。',
            pic: require(`@/assets/images/solution/advantageF4.png`)
          }
        ]
      ],
      compareArr: [
        {
          content: [
            {
              title: '左:',
              text: '试验'
            },
            {
              title: '右:',
              text: '对照'
            }
          ],
          resultTitle: '移栽阶段',
          resultText: '植株根系发育对比',

          pic: require(`@/assets/images/solution/compare1.png`)
        },
        {
          content: [
            {
              title: '上:',
              text: '对照'
            },
            {
              title: '下:',
              text: '试验'
            }
          ],
          resultTitle: '移栽阶段:',
          resultText: '使用壮苗套餐后效果对比',

          pic: require(`@/assets/images/solution/compare2.png`)
        },
        {
          content: [
            {
              title: '左:',
              text: '试验'
            },
            {
              title: '右:',
              text: '对照'
            }
          ],
          resultTitle: '结果阶段:',
          resultText: '糖度提升18.7%，果重提升29.3%',

          pic: require(`@/assets/images/solution/compare3.png`)
        },

        {
          content: [
            {
              title: '上:',
              text: '对照'
            },
            {
              title: '下:',
              text: '试验'
            },
            {
              title: '左中右:',
              text: '8天、12天、14天'
            }
          ],
          resultTitle: '货架期试验:',
          resultText: '霉变发生推迟 1 周',

          pic: require(`@/assets/images/solution/compare4.png`)
        }
      ],
      itemHeight: 0, //获取滚动元素的实际高度
      vh: 100,
      pbottom: 0,
      isShowAnimation: false,
      isFixed: false,
      num: 0,
      one: '0%',
      textBoxTranslate1: 0,
      textBoxTranslate2: 110,
      textBoxTranslate3: 0
    }
  },
  computed: {
    getpbottom() {
      this.pbottom = this.vh * 2
      return this.pbottom
    }
  },
  mounted() {
    //获取页面高度
    this.vh = (document && document.documentElement && document.documentElement.clientHeight) || window.innerHeight

    //监听窗口缩放
    window.addEventListener('resize', () => {
      this.vh = (document && document.documentElement && document.documentElement.clientHeight) || window.innerHeight
    })
    //监听滚动条事件
    window.addEventListener('scroll', this.getDomHeight)
  },
  destroyed() {
    window.removeEventListener('scroll', this.getDomHeight)
  },
  methods: {
    //获取元素高度
    getDomHeight(e) {
      //核心代码
      var scrollT = document.documentElement.scrollTop || document.body.scrollTop //滚动条距离顶部的距离
      // console.log(scrollT);
      const target = document.querySelector('.content') //获取dom元素
      const top = document.querySelector('#content').offsetTop //距离body顶部的距离

      // console.log('target', target)

      const clientRect = target.getBoundingClientRect() //获取元素的定位信息
      // let items = document.querySelectorAll('.cover-box')
      let items = document.querySelectorAll('.cover-pic')
      let items2 = document.querySelectorAll('.cover-text')

      if (scrollT >= this.vh + 60) {
        this.isFixed = true
        if (scrollT >= this.vh + 60 && this.vh * 2 + 60 >= scrollT) {
          this.textBoxTranslate1 = ((scrollT - (this.vh + 60)) / this.vh) * 200
          this.textBoxTranslate2 = -((scrollT - (this.vh + 60)) / this.vh) * 200
          // console.log('this.textBoxTranslate1', this.textBoxTranslate1)
          // console.log('this.textBoxTranslate2', this.textBoxTranslate2)
          if (this.textBoxTranslate2 < -100) {
            this.textBoxTranslate2 = -100
          }
          if (this.textBoxTranslate1 > 100) {
            this.textBoxTranslate1 = 100
          }
        }
        // if (scrollT >= this.vh * 2 + 60 && this.vh * 3 + 60 >= scrollT) {
        //   this.textBoxTranslate2 = -((scrollT - (this.vh * 2 + 60)) / this.vh) * 200
        //   console.log('this.textBoxTranslate2', this.textBoxTranslate2)
        // }
      } else {
        this.isFixed = false
      }

      // console.log('clientRect.top', clientRect.top)
      // console.log('window.innerHeight', window.innerHeight)
      if (clientRect.top <= window.innerHeight) {
        // console.log('scrollT', scrollT)

        // console.log('this.vh', this.vh)

        // if (scrollT >= this.vh) {

        //盒子距离窗口顶部的距离小于窗口高度证明进入可视区域
        // console.log('移入可视区域');
        this.isShowAnimation = true //进入可视窗口的时候，把轮播图盒子固定定位在页面上
        this.one =
          ((window.innerHeight - clientRect.top) / window.innerHeight) * 100 < 100
            ? ((window.innerHeight - clientRect.top) / window.innerHeight) * 100
            : 100 //在轮播进入的时候第一个图片高度逐渐变大
        //第一个盒子高度降低
        // console.log('这是第二个盒子');
        items[0].style.height =
          100 - ((scrollT - top) / window.innerHeight) * 200 < 0
            ? '0%'
            : 100 - ((scrollT - top) / window.innerHeight) * 200 > 100
            ? '100%'
            : 100 - ((scrollT - top) / window.innerHeight) * 200 + '%'

        // console.log('这是第三个盒子');
        // items[1].style.height =
        //   100 - ((scrollT - top - this.vh) / window.innerHeight) * 200 < 0
        //     ? '0%'
        //     : 100 - ((scrollT - top - this.vh) / window.innerHeight) * 200 > 100
        //     ? '100%'
        //     : 100 - ((scrollT - top - this.vh) / window.innerHeight) * 200 + '%'
        // console.log('这是第4个盒子');
        // items[2].style.height =
        //   100 - ((scrollT - top - this.vh - this.vh) / window.innerHeight) * 200 < 0
        //     ? '0%'
        //     : 100 - ((scrollT - top - this.vh - this.vh) / window.innerHeight) * 200 > 100
        //     ? '100%'
        //     : 100 - ((scrollT - top - this.vh - this.vh) / window.innerHeight) * 200 + '%'

        items2[0].style.opacity =
          100 - ((scrollT - top) / window.innerHeight) * 200 < 0
            ? '0%'
            : 100 - ((scrollT - top) / window.innerHeight) * 200 > 50
            ? '100%'
            : 100 - ((scrollT - top) / window.innerHeight) * 200 + '%'

        items2[1].style.opacity =
          100 - ((scrollT - top) / window.innerHeight) * 200 < 0
            ? '100%'
            : 100 - ((scrollT - top) / window.innerHeight) * 200 > 50
            ? '0%'
            : ((scrollT - top) / window.innerHeight) * 200 + '%'
        // console.log('这是第4个盒子');
        // items2[2].style.opacity =
        //   100 - ((scrollT - top - this.vh - this.vh) / window.innerHeight) * 200 < 0
        //     ? '0%'
        //     : 100 - ((scrollT - top - this.vh - this.vh) / window.innerHeight) * 200 > 100
        //     ? '100%'
        //     : 100 - ((scrollT - top - this.vh - this.vh) / window.innerHeight) * 200 + '%'

        this.textBoxTranslate = 100 - ((scrollT - top - this.vh - this.vh) / window.innerHeight) * 200 + '%'
        // console.log('this.textBoxTranslate', this.textBoxTranslate)
      }
      //盒子距离窗口顶部的距离大于盒子高度减去最后一屏的高度，这个时候到了最后一个图片需要将图片定位偏移
      if (Math.abs(clientRect.top) > clientRect.height - window.innerHeight || clientRect.top > window.innerHeight) {
        this.isShowAnimation = false
        // console.log('移出可视区域');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.solution {
  // padding-bottom: 100px;
  .picWrapper {
    box-sizing: border-box;
    padding-top: 60px;
    max-width: 100%;
    height: 945px;
    display: flex;
    justify-content: center;
    // background: #fff;
    .indexTopBg {
      width: 100%;
      height: auto;
    }
    .topProfile {
      width: 1200px;
      padding: 80px 0 80px 0;
      box-sizing: border-box;
      .topTitle {
        font-family: 字由文艺黑;
        font-size: 40px;
        font-weight: normal;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0em;

        color: #ecedeb;
        .iconfont {
          font-size: 40px;
        }
      }
      .topText {
        margin: 64px 0 40px 0;
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-weight: 300;
        line-height: 40px;
        letter-spacing: 0px;

        color: #ecedeb;
      }
      .topImg {
        display: flex;
        justify-content: center;
        img {
          // height: ;
        }
      }
    }
    .topProfile-m {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .topTitle {
        font-family: 字由文艺黑;
        font-size: 40px;
        font-weight: normal;
        line-height: 40px;
        text-align: center;
        letter-spacing: 0em;

        color: #ecedeb;
        .iconfont {
          font-size: 40px;
        }
      }
      .topText {
        margin: 20px 0 0px 0;
        font-family: HarmonyOS Sans SC;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0px;

        color: #ecedeb;
      }
      .topImg {
        display: flex;
        justify-content: center;
        margin: 0px 0 70px 0;
        img {
          // height: ;
        }
      }
    }
  }

  @media only screen and (max-width: 1199px) {
    .picWrapper {
      height: 100vh;
      padding: 0px 20px 0px 20px;
      box-sizing: border-box;

      .topProfile {
        display: none;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .picWrapper {
      .topProfile-m {
        display: none;
      }
    }
  }

  .interContainer {
    position: relative;
    .pin-spacer {
      order: 0;
      place-self: auto;
      grid-area: auto / auto / auto / auto;
      z-index: auto;
      float: none;
      flex-shrink: 1;
      display: block;
      margin: 0px;
      inset: 0px;
      position: relative;
      flex-basis: auto;
      overflow: visible;
      box-sizing: border-box;
      width: 100vw;
      height: 700vh;
      padding: 0 0 600vh;
      .interiorAnimationWrapper {
        translate: none;
        rotate: none;
        scale: none;
        left: 0px;
        top: -0.4375px;
        margin: 0px;
        max-width: 100vw;
        width: 100vw;
        max-height: 100vh;
        height: 100vh;
        padding: 0px;
        box-sizing: border-box;
        position: fixed;
        transform: translate(0px, 0px);
        .Interiors_interior__heFlo {
          display: flex;
          justify-content: center;
          height: 100vh;
          .Interiors_interiorImageWrapper__kwDBV {
            overflow: hidden;
            position: relative;
            width: 64vw;
            height: 40vw;
            margin-top: calc((100vh - 40vw) / 2);
            .responsive-image--picture {
              width: 100%;
              height: 100%;
              position: absolute;
              overflow: hidden;
              bottom: 0;
              display: block;
            }
          }
          .Interiors_interiorTextWrapper__OXo3A {
            translate: none;
            rotate: none;
            scale: none;
            transform: translate(0px, 0px);
            opacity: 1;
            align-self: center;
            display: inline-block;
            margin-top: 0;
            padding: 0 0 10vh 4vw;
            width: 24vw;
          }
        }
      }
    }
  }

  .content {
    .cover-wrapper {
      .flex {
        position: fixed !important;
        top: 0;
        left: 0;
      }
      .cover-container {
        height: 100vh;
        left: 0;
        right: 0;
        position: absolute;

        .cover-box {
          height: 100vh;
          left: auto;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          overflow: hidden;

          .detail {
            height: 100vh;
            left: auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 100vw;

            picture {
              bottom: 7.41vh;
              height: 83.2vh;
              // height: 100vh;
              left: 4.2vw;
              position: absolute;
              width: 50vw;
              overflow: hidden;
              img {
                display: block;
                width: 50vw;
                height: 83.2vh;
                object-fit: cover;
              }
            }
            .cover-text {
              width: 440px;
              height: 200px;
              // background: #fff;
              color: #222449;
              bottom: 30vh;
              // opacity: 0;
              // height: 100vh;
              left: 58.6vw;
              position: absolute;
              .cover-textTitle {
                font-family: HarmonyOS Sans SC;
                font-size: 32px;
                font-weight: 300;
                line-height: 32px;
                margin-bottom: 20px;
              }
              .cover-textContent {
                font-family: HarmonyOS Sans SC;
                font-size: 16px;
                font-weight: 300;
                line-height: 24px;
              }
            }
            .textTranslate {
              bottom: 20vh;
            }
          }
        }
      }
    }
  }
  .content-m {
    padding: 40px 20px 1px 20px;
    background: #f2f3ee;
    .cover-box-m {
      margin-bottom: 40px;
      .detail-m {
        img {
          // display: block;
          // width: 335px;
          // height: 188px;
          width: 89.33vw;
          height: 50.14vw;
          object-fit: fill;
        }
        .cover-text-m {
          color: #222449;
          margin-top: 20px;

          .cover-textTitle {
            font-family: HarmonyOS Sans SC;
            font-size: 20px;
            font-weight: 300;
            line-height: 20px;
            margin-bottom: 12px;
          }
          .cover-textContent {
            font-family: HarmonyOS Sans SC;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
          }
        }
      }
      picture {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .cover-wrapper {
      .flex {
        position: fixed !important;
        top: 0;
        left: 0;
      }
      .cover-container {
        height: 100vh;
        left: 0;
        right: 0;
        position: absolute;

        .cover-box {
          height: 100vh;
          left: auto;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          overflow: hidden;

          .detail {
            height: 100vh;
            left: auto;
            position: absolute;
            right: 0;
            top: 0;
            width: 100vw;

            picture {
              bottom: 7.41vh;
              height: 83.2vh;
              // height: 100vh;
              left: 4.2vw;
              position: absolute;
              width: 50vw;
              overflow: hidden;
              img {
                display: block;
                width: 50vw;
                height: 83.2vh;
                object-fit: cover;
              }
            }
            .cover-text {
              width: 440px;
              height: 200px;
              // background: #fff;
              color: #222449;
              bottom: 30vh;
              // opacity: 0;
              // height: 100vh;
              left: 58.6vw;
              position: absolute;
              .cover-textTitle {
                font-family: HarmonyOS Sans SC;
                font-size: 32px;
                font-weight: 300;
                line-height: 32px;
                margin-bottom: 20px;
              }
              .cover-textContent {
                font-family: HarmonyOS Sans SC;
                font-size: 14px;
                font-weight: 300;
                line-height: 24px;
              }
            }
            .textTranslate {
              bottom: 20vh;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1199px) {
    .content {
      display: none;
    }
  }
  @media only screen and (min-width: 1200px) {
    .content-m {
      display: none;
    }
  }

  .backgroundDetail {
    width: 100%;
    height: 700px;
    background: #f2f3ee;
    position: relative;
    .farm1 {
      position: absolute;
      top: 80px;
      right: 45.3vw;
      width: 960px;
      height: 540px;
    }
    .farm1DetailBoxTitle {
      position: absolute;
      top: 288px;
      left: 58.3vw;
      font-family: HarmonyOS Sans SC;
      font-size: 32px;
      font-weight: 300;
      line-height: 32px;
      letter-spacing: 0em;

      color: #222449;
    }
    .farm1DetailBoxText {
      width: 440px;
      position: absolute;
      top: 340px;
      left: 58.3vw;
      font-family: HarmonyOS Sans SC;
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      text-align: justify; /* 浏览器可能不支持 */
      letter-spacing: 0em;

      color: #222449;
    }
  }
  .advantage {
    padding-top: 80px;
    padding-bottom: 40px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    .advantageItem2 {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
      flex-direction: row-reverse;
    }
    .advantageItem1 {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
    }
    .advantageImage {
      width: 580px;
      height: 580px;
    }
    .advantageBox {
      box-sizing: border-box;
      padding: 0 90px 0 90px;
      background: #f2f3ee;
      width: 580px;
      height: 580px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      .advantageTitle {
        font-family: HarmonyOS Sans SC;
        font-size: 32px;
        font-weight: 300;
        line-height: 48px;
        letter-spacing: 0em;
        margin-bottom: 40px;
        color: #0e293b;
      }
      .advantageBoxText {
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-weight: 300;
        line-height: 32px;
        text-align: justify; /* 浏览器可能不支持 */
        letter-spacing: 0em;

        color: #0e293b;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
  }
  @media only screen and (max-width: 1199px) {
    .advantage {
      padding: 40px 40px 20px 40px;
      box-sizing: border-box;
      .advantageItem2 {
        width: 78.7vw;
        flex-direction: column;
        align-items: center;
      }
      .advantageItem1 {
        width: 78.7vw;
        flex-direction: column;
        align-items: center;
      }
      .advantageImage {
        width: 78.7vw;
        height: 78.7vw;
      }
      .advantageBoxSpecial {
        height: 106vw !important;
      }
      .advantageBox {
        padding: 0 6vw 0 6vw;
        width: 78.7vw;
        height: 78.7vw;

        .advantageTitle {
          font-size: 20px;
          // line-height: 20px;
          margin-bottom: 20px;
          p {
            line-height: 24px;
          }
        }
        .advantageBoxText {
          font-size: 14px;
          line-height: 28px;
        }
      }
    }
  }

  .compareCase {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    .compareContent {
      width: 1200px;
      padding-bottom: 80px;
      box-sizing: border-box;
      .compareBigTitle {
        font-family: HarmonyOS Sans SC;
        font-size: 40px;
        font-weight: 900;
        line-height: 40px;
        letter-spacing: 0em;

        color: #333333;
        margin-bottom: 40px;
      }
      .compareContentBox {
        width: 100%;
        // display: flex;
        // flex-wrap: wrap;
        display: grid;
        grid-gap: 40px 20px; /* 行列*/
        grid-template-columns: repeat(2, auto); /* 总共2列，占据剩余空间的比例相等 */
        grid-template-rows: repeat(2, auto); /* 总共两行，自适应高度 */
        .compareContentItem {
          display: flex;
          flex-direction: column;
          img {
            width: 590px;
            height: 285px;
            margin-bottom: 16px;
          }
          .title {
            font-family: HarmonyOS Sans SC;
            font-size: 16px;
            font-weight: 700;
            line-height: 32px;
            letter-spacing: 0em;

            color: #333333;
          }
          .text {
            font-family: HarmonyOS Sans SC;
            font-size: 16px;
            font-weight: 300;
            line-height: 32px;
            letter-spacing: 0em;

            color: #333333;
          }
          .compareDetailBoxDes {
            display: flex;
            .compareDetailBoxDesLine {
              display: flex;
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .compareCase {
      display: none;
    }
  }
}
</style>